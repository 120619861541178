<script setup></script>

<template>
  <div class="locale-switcher">
    <div class="locale-changer">
      <select v-model="$i18n.locale" @change="updatelocale"> 
        <option
          v-for="locale in $i18n.availableLocales"
          :key="`locale-${locale}`"
          :value="locale"
          @click="updatelocale"
        >
          {{ locale }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updatelocale (event) {
      const selectedLocale = event.target.value;
      const html = document.documentElement;
      if (selectedLocale == 'ar') {
        html.setAttribute('dir', 'rtl');
      } else {
        html.setAttribute('dir', 'ltr');
      }
      localStorage.setItem('langCode', selectedLocale)
    }
  }
}
</script>