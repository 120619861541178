<template> 
<button @click="toggleLeftNav" class="toggle-btn active" v-if="expandLeftNav">
    <span class="pi pi-chevron-left"></span>
    </button>
    <button @click="toggleLeftNav" class="toggle-btn" v-else>
    <span class="pi pi-chevron-right"></span>
    </button>

<nav id="sidebar" class="active" :class="{ open: expandLeftNav }">
    <div class="app-logo-box">
        <img 
  :src="expandLeftNav ? require('@/assets/images/t-logo-expanded.svg') : require('@/assets/images/t-logo.svg')" 
  alt="Logo" 
  class="app-logo" 
  height="36" 
/>
  </div>
<!-- <div class="app-logo-box">
<img src="@/assets/images/t-logo.svg" alt="" class="app-logo" width="36" height="36" /> 
</div> -->
<ul class="list-unstyled components">
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('campaigns') ? '' : 'noHover'" :to="'/Campdashboard/'+channelType"  v-tooltip.right="expandLeftNav ? null : $t('module.campaigns')" >
<span class="wxt-campaign"></span>
<div class="link-name" > {{ $t("module.campaigns") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('schedule') ? '' : 'noHover'" to="/Schedulelist" v-tooltip.right="expandLeftNav ? null : $t('module.schedule')">
<span class="wxt-calendar"></span>
<div class="link-name" > {{ $t("module.schedule") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('contacts') ? '' : 'noHover'" to="/Contacts" v-tooltip.right="expandLeftNav ? null : $t('module.contacts')">
<span class="wxt-contact"></span>
<div class="link-name" > {{ $t("module.contacts") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('baseline') ? '' : 'noHover'" to="/Baseline" v-tooltip.right="expandLeftNav ? null : $t('module.baseline')">
<span class="wxt-baseline"></span>
<div class="link-name" > {{ $t("module.baseline") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('reports') ? '' : 'noHover'" to="/Reportslist" v-tooltip.right="expandLeftNav ? null : $t('module.reports')">
<span class="wxt-barchart"></span>
<div class="link-name" > {{ $t("module.reports") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" to="/Approvals" v-tooltip.right="expandLeftNav ? null : $t('module.approvals')">
<span class="wxt-check"></span>
<div class="link-name"> {{ $t("module.approvals") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" to="/ExportList" v-tooltip.right="expandLeftNav ? null : $t('module.exports')">
<span class="wxt-export"></span>
<div class="link-name" > {{ $t("module.exports") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('links') ? '' : 'noHover'" to="/LinkDashboard" v-tooltip.right="expandLeftNav ? null : $t('module.links')">
<span class="wxt-url"></span>
<div class="link-name" > {{ $t("module.links") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('user_profile') ? '' : 'noHover'" to="/Userprofile" v-tooltip.right="expandLeftNav ? null : $t('module.userProfile')">
<span class="wxt-user"></span>
<div class="link-name" > {{ $t("module.userProfile") }}</div>
</router-link>
</li>
<li v-if="resourceScopeMap['settings.channels']" class="mt-auto">
<router-link exact-active-class="exact-active" :to="'/SettingsList/'+tab" v-tooltip.right="expandLeftNav ? null : $t('module.settings')">
<span class="wxt-settings"></span>
<div class="link-name" > {{ $t("module.settings") }}</div>
</router-link>
</li>
<li class="">
<router-link to="" v-tooltip.right="expandLeftNav ? null : $t('module.help')">
<span class="wxt-help"></span>
<div class="link-name" > {{ $t("module.help") }}</div>
</router-link>
</li>
</ul>
</nav>
</template>
<script>
import { mapGetters } from 'vuex'
import $ from 'jquery'
let accessToken = localStorage.getItem('Accesstoken')
$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active')
        $(this).toggleClass('active')
    })
})
export default {
    name: 'Sidebar',
    data () {
        return {
            campaign: true,
            expandLeftNav: false, // Sidebar visibility state
        }
    },
    props: {
        channelType: {
            type: String,
            default: 'sms'
        },
        tab: {
            type: String,
            default: 'channelSettings'
        }
    },
    computed: {
        ...mapGetters('Auth/KeycloakAuth', ['modSubModSet'])
    },
    methods: {
        created () {
            if (localStorage.getItem('reloaded')) {
                localStorage.removeItem('reloaded')
            } else {
                localStorage.setItem('reloaded', '1')
                location.reload()
            }
            if (!accessToken) {
                this.$router.push('/')
            }
        },
        logout () {
            localStorage.removeItem('Accesstoken')
            this.$router.push('/')
        },
        toggleLeftNav() {
      this.expandLeftNav = !this.expandLeftNav;
    },
    },
    computed: {
        ...mapGetters('Auth/KeycloakAuth', ['modSubModSet', 'resourceScopeMap'])
    }
}
</script>
 