import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Config from '../config/development'
import i18n from '../i18n'
const t = i18n.global.t
import { getMockError, isMockError, getMockResponse } from '../mock'
// import * as Sentry from '@sentry/browser'
import { getToast } from '../utils'

let occurence = 1

const errorMap = {
    "Could not download the file.": "couldNotDownladFile"
};

const errorResponseHandler = error => {
    // Check if the request came from a mocked API
    if (isMockError(error)) {
        return getMockResponse(error)
    }
    // Sentry.captureException(error)
    console.log('Error in Axios call',  error)
    // check for errorHandle config
    if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
        return Promise.reject(error)
    }

    // if has response show the error
    if (error.response) {
        // console.log(error.status, error.message, error.response);
        if ([200, 201, 208, 400, 401].includes(error.response.status)) {
            if (error.response.status === 401 && occurence === 1 && error.response.data && error.response.data.displayMsg) {
                console.log('Error Response Before add:::::::::::', error.response)
                occurence += 1
                getToast().add({
                    severity: 'error',
                    summary: (error.response.data && error.response.data.displayMsg && error.response.data.displayMsg.e && error.response.data.displayMsg.e.msg) || t('toast.unknownError'),
                    life: 3000
                })
                setTimeout(() => {
                    occurence = 1
                }, 3000)
                console.log('Error Response After add:::::::::::', getToast().add)
            }

            return error.response
        }
        if (error.response.status === 404) {
            console.log(error.response.data.message)
            return { data: { data: [], results: [] } }
        }
        if (error.response.data && error.response.data.message && (typeof (error.response.data.message) === 'string' || error.response.data.message.msg)) {
            getToast().add({ severity: 'error', summary: typeof (error.response.data.message) === 'string' ? (errorMap[error.response.data.message] ? t(`toast.${errorMap[error.response.data.message]}`) : error.response.data.message) : error.response.data.message.msg, life: 3000 })
            return { data: { data: [], results: [] } }
        } else {
            if (error.response.data.type != 'templates') { 
                getToast().add({ severity: 'error', summary: t('toast.unableToProcessRequest'), life: 3000 })
                return { data: { data: [], results: [] } } // temp fix need to check R&D on more promises concepts
            }
        }
    } else if (error.message) {
        getToast().add({ severity: 'error', summary: t('toast.unableToProcessRequest'), life: 3000 })
        return { data: { data: [], results: [] } } // temp fix need to check R&D on more promises concepts
        // Network error will generally come here
    }
}

let axiosWebApi = axios.create({
    baseURL: Config.Backend_URL,
    headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${localStorage.Accesstoken}`,
        authorization: localStorage.Accesstoken,
        oauthAuthorization: localStorage.msalaccessToken
    }
})

// apply interceptor on request
axiosWebApi.interceptors.request.use(request => {
    // TODO: Authentication for routing can be done here
    // Logic to catch request from a mocked API
    if (!request.data) request.data = {}
    request.data.loggedVia = localStorage.getItem('loggedVia');
    request.headers.oauthAuthorization = localStorage.getItem('msalaccessToken')
    request.headers.authorization = localStorage.getItem('Accesstoken')
    if (Config.mock || request.isMock) {
        return getMockError(request)
    } else {
        if (Config.isBasic || request.isBasic) {
            var basicAuth = Config.basicAuth || request.basicAuth
        }
        return request
    }
})

// apply interceptor on response
axiosWebApi.interceptors.response.use(
    response => response,
    errorResponseHandler
)

export default axiosWebApi
