import i18n from '../i18n'
import { computed } from 'vue';
const t = i18n.global.t

const templateButtonsLimit = {
    'PHONE_NUMBER': 1,
    'URL': 2,
    'CALL_TO_ACTION': 4,
    'QUICK_REPLY': 10,
    'COPY_CODE': 1,
    'MARKETING_OPTOUT': 1,
    'COMPLETE_FORM': 1
}
export default {
    currencies: [
        { name: t('currency.AFA'), code: 'AFA' },
        { name: t('currency.ALL'), code: 'ALL' },
        { name: t('currency.DZD'), code: 'DZD' },
        { name: t('currency.AOA'), code: 'AOA' },
        { name: t('currency.ARS'), code: 'ARS' },
        { name: t('currency.AMD'), code: 'AMD' },
        { name: t('currency.AWG'), code: 'AWG' },
        { name: t('currency.AUD'), code: 'AUD' },
        { name: t('currency.AZN'), code: 'AZN' },
        { name: t('currency.BSD'), code: 'BSD' },
        { name: t('currency.BHD'), code: 'BHD' },
        { name: t('currency.BDT'), code: 'BDT' },
        { name: t('currency.BBD'), code: 'BBD' },
        { name: t('currency.BYR'), code: 'BYR' },
        { name: t('currency.BEF'), code: 'BEF' },
        { name: t('currency.BZD'), code: 'BZD' },
        { name: t('currency.BMD'), code: 'BMD' },
        { name: t('currency.BTN'), code: 'BTN' },
        { name: t('currency.BTC'), code: 'BTC' },
        { name: t('currency.BOB'), code: 'BOB' },
        { name: t('currency.BAM'), code: 'BAM' },
        { name: t('currency.BWP'), code: 'BWP' },
        { name: t('currency.BRL'), code: 'BRL' },
        { name: t('currency.GBP'), code: 'GBP' },
        { name: t('currency.BND'), code: 'BND' },
        { name: t('currency.BGN'), code: 'BGN' },
        { name: t('currency.BIF'), code: 'BIF' },
        { name: t('currency.KHR'), code: 'KHR' },
        { name: t('currency.CAD'), code: 'CAD' },
        { name: t('currency.CVE'), code: 'CVE' },
        { name: t('currency.KYD'), code: 'KYD' },
        { name: t('currency.XOF'), code: 'XOF' },
        { name: t('currency.XAF'), code: 'XAF' },
        { name: t('currency.XPF'), code: 'XPF' },
        { name: t('currency.CLP'), code: 'CLP' },
        { name: t('currency.CNY'), code: 'CNY' },
        { name: t('currency.COP'), code: 'COP' },
        { name: t('currency.KMF'), code: 'KMF' },
        { name: t('currency.CDF'), code: 'CDF' },
        { name: t('currency.CRC'), code: 'CRC' },
        { name: t('currency.HRK'), code: 'HRK' },
        { name: t('currency.CUC'), code: 'CUC' },
        { name: t('currency.CZK'), code: 'CZK' },
        { name: t('currency.DKK'), code: 'DKK' },
        { name: t('currency.DJF'), code: 'DJF' },
        { name: t('currency.DOP'), code: 'DOP' },
        { name: t('currency.XCD'), code: 'XCD' },
        { name: t('currency.EGP'), code: 'EGP' },
        { name: t('currency.ERN'), code: 'ERN' },
        { name: t('currency.EEK'), code: 'EEK' },
        { name: t('currency.ETB'), code: 'ETB' },
        { name: t('currency.EUR'), code: 'EUR' },
        { name: t('currency.FKP'), code: 'FKP' },
        { name: t('currency.FJD'), code: 'FJD' },
        { name: t('currency.GMD'), code: 'GMD' },
        { name: t('currency.GEL'), code: 'GEL' },
        { name: t('currency.DEM'), code: 'DEM' },
        { name: t('currency.GHS'), code: 'GHS' },
        { name: t('currency.GIP'), code: 'GIP' },
        { name: t('currency.GRD'), code: 'GRD' },
        { name: t('currency.GTQ'), code: 'GTQ' },
        { name: t('currency.GNF'), code: 'GNF' },
        { name: t('currency.GYD'), code: 'GYD' },
        { name: t('currency.HTG'), code: 'HTG' },
        { name: t('currency.HNL'), code: 'HNL' },
        { name: t('currency.HKD'), code: 'HKD' },
        { name: t('currency.HUF'), code: 'HUF' },
        { name: t('currency.ISK'), code: 'ISK' },
        { name: t('currency.INR'), code: 'INR' },
        { name: t('currency.IDR'), code: 'IDR' },
        { name: t('currency.IRR'), code: 'IRR' },
        { name: t('currency.IQD'), code: 'IQD' },
        { name: t('currency.ILS'), code: 'ILS' },
        { name: t('currency.ITL'), code: 'ITL' },
        { name: t('currency.JMD'), code: 'JMD' },
        { name: t('currency.JPY'), code: 'JPY' },
        { name: t('currency.JOD'), code: 'JOD' },
        { name: t('currency.KZT'), code: 'KZT' },
        { name: t('currency.KES'), code: 'KES' },
        { name: t('currency.KWD'), code: 'KWD' },
        { name: t('currency.KGS'), code: 'KGS' },
        { name: t('currency.LAK'), code: 'LAK' },
        { name: t('currency.LVL'), code: 'LVL' },
        { name: t('currency.LBP'), code: 'LBP' },
        { name: t('currency.LSL'), code: 'LSL' },
        { name: t('currency.LRD'), code: 'LRD' },
        { name: t('currency.LYD'), code: 'LYD' },
        { name: t('currency.LTL'), code: 'LTL' },
        { name: t('currency.MOP'), code: 'MOP' },
        { name: t('currency.MKD'), code: 'MKD' },
        { name: t('currency.MGA'), code: 'MGA' },
        { name: t('currency.MWK'), code: 'MWK' },
        { name: t('currency.MYR'), code: 'MYR' },
        { name: t('currency.MVR'), code: 'MVR' },
        { name: t('currency.MRO'), code: 'MRO' },
        { name: t('currency.MUR'), code: 'MUR' },
        { name: t('currency.MXN'), code: 'MXN' },
        { name: t('currency.MDL'), code: 'MDL' },
        { name: t('currency.MNT'), code: 'MNT' },
        { name: t('currency.MAD'), code: 'MAD' },
        { name: t('currency.MZM'), code: 'MZM' },
        { name: t('currency.MMK'), code: 'MMK' },
        { name: t('currency.NAD'), code: 'NAD' },
        { name: t('currency.NPR'), code: 'NPR' },
        { name: t('currency.ANG'), code: 'ANG' },
        { name: t('currency.TWD'), code: 'TWD' },
        { name: t('currency.NZD'), code: 'NZD' },
        { name: t('currency.NIO'), code: 'NIO' },
        { name: t('currency.NGN'), code: 'NGN' },
        { name: t('currency.KPW'), code: 'KPW' },
        { name: t('currency.NOK'), code: 'NOK' },
        { name: t('currency.OMR'), code: 'OMR' },
        { name: t('currency.PKR'), code: 'PKR' },
        { name: t('currency.PAB'), code: 'PAB' },
        { name: t('currency.PGK'), code: 'PGK' },
        { name: t('currency.PYG'), code: 'PYG' },
        { name: t('currency.PEN'), code: 'PEN' },
        { name: t('currency.PHP'), code: 'PHP' },
        { name: t('currency.PLN'), code: 'PLN' },
        { name: t('currency.QAR'), code: 'QAR' },
        { name: t('currency.RON'), code: 'RON' },
        { name: t('currency.RUB'), code: 'RUB' },
        { name: t('currency.RWF'), code: 'RWF' },
        { name: t('currency.SVC'), code: 'SVC' },
        { name: t('currency.WST'), code: 'WST' },
        { name: t('currency.SAR'), code: 'SAR' },
        { name: t('currency.RSD'), code: 'RSD' },
        { name: t('currency.SCR'), code: 'SCR' },
        { name: t('currency.SLL'), code: 'SLL' },
        { name: t('currency.SGD'), code: 'SGD' },
        { name: t('currency.SKK'), code: 'SKK' },
        { name: t('currency.SBD'), code: 'SBD' },
        { name: t('currency.SOS'), code: 'SOS' },
        { name: t('currency.ZAR'), code: 'ZAR' },
        { name: t('currency.KRW'), code: 'KRW' },
        { name: t('currency.XDR'), code: 'XDR' },
        { name: t('currency.LKR'), code: 'LKR' },
        { name: t('currency.SHP'), code: 'SHP' },
        { name: t('currency.SDG'), code: 'SDG' },
        { name: t('currency.SRD'), code: 'SRD' },
        { name: t('currency.SZL'), code: 'SZL' },
        { name: t('currency.SEK'), code: 'SEK' },
        { name: t('currency.CHF'), code: 'CHF' },
        { name: t('currency.SYP'), code: 'SYP' },
        { name: t('currency.STD'), code: 'STD' },
        { name: t('currency.TJS'), code: 'TJS' },
        { name: t('currency.TZS'), code: 'TZS' },
        { name: t('currency.THB'), code: 'THB' },
        { name: t('currency.TOP'), code: 'TOP' },
        { name: t('currency.TTD'), code: 'TTD' },
        { name: t('currency.TND'), code: 'TND' },
        { name: t('currency.TRY'), code: 'TRY' },
        { name: t('currency.TMT'), code: 'TMT' },
        { name: t('currency.UGX'), code: 'UGX' },
        { name: t('currency.UAH'), code: 'UAH' },
        { name: t('currency.AED'), code: 'AED' },
        { name: t('currency.UYU'), code: 'UYU' },
        { name: t('currency.USD'), code: 'USD' },
        { name: t('currency.UZS'), code: 'UZS' },
        { name: t('currency.VUV'), code: 'VUV' },
        { name: t('currency.VEF'), code: 'VEF' },
        { name: t('currency.VND'), code: 'VND' },
        { name: t('currency.YER'), code: 'YER' },
        { name: t('currency.ZMK'), code: 'ZMK' }
    ],
    channelToCostSettingsMap: { sms: computed(() => t('channel.sms')), tc: computed(() => t('channel.tc')), rcs: computed(() => t('channel.rcs')), wpsms: computed(() => t('channel.wpsms')) }, // 'et': 'Email', 'wpsms': 'Whatsapp',
    audienceTypeMapping: {
        1: 'Insights',
        2: 'Lookalike Audience',
        3: 'Custom Cohorts',
        4: 'Broadcasts'
    },
    goalMapping: {
    // 'cc': 'Credit Card',
    // 'is': 'Life Insurance',
    // 'ld': 'Lending',
        'gm:howz': 'Gaming Howz',
        'gm:my11': 'Gaming My11',
        'ld:mob': 'Lending Mob',
        'ld:navi': 'Lending Navi'
    },
    goals: [
    // {value:'cc',name:'Credit Card'},
    // {value:'is',name:'Life Insurance'},
    // {value:'lending',name:'Lending'},
        { value: 'ld:mob', name: 'Lending Mob' },
        { value: 'ld:navi', name: 'Lending Navi' },
        { value: 'gm:my11', name: 'Gaming My11' },
        { value: 'gm:howz', name: 'Gaming Howz' }
    ],
    whatsappRegex: /{{[0-9]+}}/g,
    whatsapp_vendor_list: [{ name: 'Wisely', value: 'wisely' }],
    truecaller_vendor_list: [{ name: 'Wisely', value: 'wisely' }],
    channelList: { sms: computed(() => t('channel.sms')), tc: computed(() => t('channel.tc')), rcs: computed(() => t('channel.rcs')), wpsms: computed(() => t('channel.wpsms')) },
    TypeFromRouteMap: {
        sms: 'sms',
        whatsapp: 'wpsms',
        truecaller: 'tc',
        rcs: 'rcs'
    },
    routeFromTypeMap: {
        sms: 'sms',
        wpsms: 'whatsapp',
        tc: 'truecaller',
        rcs: 'rcs'
    },
    channelToImageMap: {
        sms: require('@/assets/images/sms.svg'),
        wpsms: require('@/assets/images/whatsapp.svg'),
        tc: require('@/assets/images/truecaller.svg'),
        rcs: require('@/assets/images/rcs.svg')
    },
    messagesTypeList: [
        { name: 'Transactional', value: 'TRANS' },
        { name: 'Promotional', value: 'PROMO' }
    ],
    truecallerMsgTypes: [
        { label: computed(() => t('contentPage.msgType.text')), value: 'text' },
        // { label: computed(() => t('contentPage.msgType.richText')), value: 'richtext' },
        { label: computed(() => t('contentPage.msgType.image')), value: 'image' },
        { label: computed(() => t('contentPage.msgType.document')), value: 'document' },
        { label: computed(() => t('contentPage.msgType.video')), value: 'video' },
        { label: computed(() => t('contentPage.msgType.interactive')), value: 'interactive' }
    ],
    truecallerHeaderTypes: [
        { label: computed(() => t('contentPage.msgType.text')), value: 'text' },
        // { label: computed(() => t('contentPage.msgType.richText')), value: 'richtext' },
        { label: computed(() => t('contentPage.msgType.image')), value: 'image' },
        { label: computed(() => t('contentPage.msgType.document')), value: 'document' },
        { label: computed(() => t('contentPage.msgType.video')), value: 'video' }
    ],
    tcButtonTypes: [
        { label: computed(() => t('contentPage.btnType.reply')), value: 'reply' },
        { label: computed(() => t('contentPage.btnType.deeplink')), value: 'deeplink' },
        { label: computed(() => t('contentPage.btnType.telephoneLink')), value: 'tellink' }
    ],
    channelsList: [
        { name: computed(() => t('channel.sms')), value: 'sms' },
        { name: computed(() => t('channel.truecaller')), value: 'tc' },
        { name: 'RCS', value: 'rcs' },
        { name: computed(() => t('channel.whatsapp')), value: 'wpsms' }
    ],
    rcsMsgTypes: [
        { label: computed(() => t('contentPage.msgType.text')), value: 'text' },
        { label: computed(() => t('contentPage.msgType.richText')), value: 'richtext' },
        { label: computed(() => t('contentPage.msgType.image')), value: 'image' },
        { label: computed(() => t('contentPage.msgType.document')), value: 'document' },
        { label: computed(() => t('contentPage.msgType.video')), value: 'video' },
        { label: computed(() => t('contentPage.msgType.interactive')), value: 'interactive' }],
    rcsHeaderTypes: [
        { label: computed(() => t('contentPage.msgType.text')), value: 'text' },
        { label: computed(() => t('contentPage.msgType.richText')), value: 'richtext' },
        { label: computed(() => t('contentPage.msgType.image')), value: 'image' },
        { label: computed(() => t('contentPage.msgType.document')), value: 'document' },
        { label: computed(() => t('contentPage.msgType.video')), value: 'video' }],
    rcsButtonTypes: [
        { label: computed(() => t('contentPage.btnType.reply')), value: 'reply' },
        { label: computed(() => t('contentPage.btnType.deeplink')), value: 'deeplink' },
        { label: computed(() => t('contentPage.btnType.telephoneLink')), value: 'tellink' }],
    rcsButtonTitles: {
        'dialer_action': [t('contentPage.translationRcsButtonTitles.dialerAction')],
        'reply': [t('contentPage.translationRcsButtonTitles.reply')],
        'view_location_query': [t('contentPage.translationRcsButtonTitles.viewLocationQuery')],
        'view_location_latlong': [t('contentPage.translationRcsButtonTitles.viewLocationLatLong'), t('contentPage.translationRcsButtonTitles.latitude'), t('contentPage.translationRcsButtonTitles.longitude'), t('contentPage.translationRcsButtonTitles.label')],
        'url_action': [t('contentPage.translationRcsButtonTitles.urlAction'), t('contentPage.translationRcsButtonTitles.urlToOpen')],
        'share_location': [t('contentPage.translationRcsButtonTitles.shareLocation')],
        'calendar_event': [t('contentPage.translationRcsButtonTitles.calendarEvent'), t('contentPage.translationRcsButtonTitles.title'), t('contentPage.translationRcsButtonTitles.description')]
    },
    rcsMsgCategory: {
        'text_message': t('contentPage.translationRcsMsgCategory.textMessage'),
        'rich_text': t('contentPage.translationRcsMsgCategory.richText'),
        'carousel': t('contentPage.translationRcsMsgCategory.carousel'),
    },
    rcsShortCutsMap: {
        [t('contentPage.translationRcsButtonTitles.latitude')]: 'latitude',
        [t('contentPage.translationRcsButtonTitles.longitude')]: 'longitude',
        [t('contentPage.translationRcsButtonTitles.urlToOpen')]: 'url',
        [t('contentPage.translationRcsButtonTitles.label')]: 'label'
    },
    rcsTemplateOptions: {
        'actions': [
            { vcKey: 'reply', vcValue: 'Reply', vcAPIKey: 'reply', vcIcon: '', isImageIcon: false },
            { vcKey: 'url_action', vcValue: 'URL Action', vcAPIKey: 'url_action', vcIcon: 'pi-globe', isImageIcon: false },
            { vcKey: 'dialer_action', vcValue: 'Dialer Action', vcAPIKey: 'dialer_action', vcIcon: 'pi-phone', isImageIcon: false },
            { vcKey: 'vlocation', vcValue: 'View Location (Lat/Long)', vcAPIKey: 'view_location_latlong', vcIcon: 'pi-map-marker', isImageIcon: false },
            { vcKey: 'vlocation-q', vcValue: 'View Location (Query)', vcAPIKey: 'view_location_query', vcIcon: 'pi-map-marker', isImageIcon: false },
            { vcKey: 'rlocation', vcValue: 'Share Location', vcAPIKey: 'share_location', vcIcon: 'gps.svg', isImageIcon: true },
            { vcKey: 'event', vcValue: 'Calendar Event', vcAPIKey: 'calendar_event', vcIcon: 'pi-calendar', isImageIcon: false }
        ],
        'limit': {
            'text_message': 11,
            'rich_card': 4,
            'carousel': 4
        },
        'orientation': [
            { vcKey: 'VERTICAL', vcValue: 'Vertical' },
            { vcKey: 'HORIZONTAL', vcValue: 'Horizontal' }
        ],
        'alignment': [
            { vcKey: 'LEFT', vcValue: 'Left' },
            { vcKey: 'RIGHT', vcValue: 'Right' }
        ],
        'height': [
            { vcKey: 'SHORT_HEIGHT', vcValue: 'Short' },
            { vcKey: 'MEDIUM_HEIGHT', vcValue: 'Medium' }
        ],
        'width': [
            { vcKey: 'SHORT_WIDTH', vcValue: 'Small' },
            { vcKey: 'MEDIUM_WIDTH', vcValue: 'Medium' }
        ],
        'carousel_card': {
            min: 2,
            max: 10
        },
        'msg_desc_limit': {
            'text_message': 2500,
            'rich_card': 2000,
            'carousel': 2000
        }
    },
    UPLOADMODULEKEYS: {
        CHANNEL_WABA_PROFILE: 'CHANNEL_WABA_PROFILE',
        CHANNEL_WEBHOOK_SSL: 'CHANNEL_WEBHOOK_SSL',
        CHANNEL_TC_BUSINESS_LOGO: 'CHANNEL_TC_BUSINESS_LOGO',
        CHANNEL_TC_PROFILE: 'CHANNEL_TC_PROFILE',
        API_OUTGOING_SECURITY_SSL: 'API_OUTGOING_SECURITY_SSL',
        API_OUTGOING_WEBHOOK_SSL: 'API_OUTGOING_WEBHOOK_SSL',
        API_CONV_SECURITY_SSL: 'API_CONV_SECURITY_SSL',
        API_CONV_WEBHOOK_MO_SSC: 'API_CONV_WEBHOOK_MO_SSC',
        API_CONV_WEBHOOK_MT_SSC: 'API_CONV_WEBHOOK_MT_SSC',
        SETTINGS_BUSINESS_LOGO: 'SETTINGS_BUSINESS_LOGO',
        SETTINGS_BUSINESS_DOC: 'SETTINGS_BUSINESS_DOC',
        SETTINGS_PROFILE_PIC: 'SETTINGS_PROFILE_PIC',
        CHANNEL_RCS_BANNER_IMAGE: 'CHANNEL_RCS_BANNER_IMAGE',
        CHANNEL_RCS_AGENT_LOGO: 'CHANNEL_RCS_AGENT_LOGO',
        CHANNEL_RCS_BOT_LOGO: 'CHANNEL_RCS_BOT_LOGO',
        APPS_TEMPLATE_LOOKUP: 'APPS_TEMPLATE_LOOKUP',
        RCS_TEMPLATES_MEDIA: 'RCS_TEMPLATES_MEDIA',
        WAPP_TEMPLATES_MEDIA: 'WAPP_TEMPLATES_MEDIA'
    },
    templateButtonsMaxLimit: 10,
    templateButtons: [
        {
            vcKey: 'NONE',
            vcValue: 'None',
            id: 7
        },
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'URL',
            id: 2,
            vcValue: 'Call to Action - Visit Website',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['URL'],
            optionDisable: false,
            icon: 'pi pi-external-link',
            isCardButton: true
        },
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'PHONE_NUMBER',
            id: 1,
            vcValue: 'Call to Action - Phone Number',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['PHONE_NUMBER'],
            optionDisable: false,
            icon: 'pi pi-phone',
            isCardButton: true
        },
        {
            vcKey: 'QUICK_REPLY',
            subKey: 'QUICK_REPLY',
            vcValue: 'Quick Reply (Custom)',
            limit: templateButtonsLimit['QUICK_REPLY'],
            id: 6,
            optionDisable: false,
            icon: 'pi pi-replay',
            isCardButton: true
        },
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'COPY_CODE',
            id: 3,
            vcValue: 'Call to Action - Offer Code',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['COPY_CODE'],
            optionDisable: false,
            icon: 'pi pi-copy',
            isCardButton: false
        },
        /*
        {
        vcKey:'QUICK_REPLY',
        subKey:'MARKETING_OPTOUT',
        id:4,
        vcValue:'Marketing opt-out',
        limit: templateButtonsLimit['QUICK_REPLY'],
        subLimit : templateButtonsLimit['MARKETING_OPTOUT'],
        optionDisable:false,
        icon:"pi pi-times-circle",
        isCardButton:false,
        }, */
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'FLOW',
            id: 5,
            vcValue: 'Complete Form',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['COMPLETE_FORM'],
            optionDisable: false,
            icon: 'pi pi-list',
            isCardButton: false
        }
    ],
    channelEvMap: {
        'sms': {
            'click': 'sms_clicked'
        },
        'tc': {
            'click': '_tc_clicked'
        },
        'rcs': {
            'click': '_rcs_clicked'
        },
        'wpsms': {
            'click': '_whatsapp_clicked'
        }
    },
    LOGIN_LIMIT: 3
}
