import { createI18n } from "vue-i18n";
import en from "./constants/locales/en.json";
import ar from "./constants/locales/ar.json";

function loadLocaleMessages() {
  const locales = [{ en: en }, { ar: ar }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}

const getLangCode = () => {
  //add keycloack token logic as well
  let currLocale = 'en';
  if (localStorage.getItem('langCode')) {
    currLocale = localStorage.getItem('langCode')
  }
  const html = document.documentElement;
    if (currLocale == 'ar') {
      html.setAttribute('dir', 'rtl');
    } else {
      html.setAttribute('dir', 'ltr');
    }
  return currLocale
}
export default createI18n({
  locale: getLangCode(),
  fallbackLocale: "en",
  messages: loadLocaleMessages()
});